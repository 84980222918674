import { Menu } from '@mantine/core';
import clsx from 'clsx';
import { type ComponentProps, type JSX, useState } from 'react';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import type { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { BetaLabel } from 'ts/commons/BetaLabel';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { Divider } from 'ts/components/Divider';
import { type SemanticICONS } from 'ts/components/Generic';
import { Icon } from 'ts/components/Icon';

/** Props for ActivePerspectiveSubViewMenu and PerspectiveSubViewPopupMenu. */
type PerspectiveSubViewMenuProps = {
	views: ViewDescriptor[];
	perspectiveDescriptor: PerspectiveViewDescriptorBase;
	defaultView: ViewDescriptor;
};

/** Creates the menu for switching views within a perspective from an expanded sidebar. */
export function ActivePerspectiveSubViewMenu({
	views,
	perspectiveDescriptor,
	defaultView
}: PerspectiveSubViewMenuProps): JSX.Element | null {
	const hash = useNavigationHash();
	if (views.length === 0) {
		return (
			<PerspectiveLinkSidebarEntry
				perspectiveDescriptor={perspectiveDescriptor}
				active
				view={defaultView}
				hash={hash}
			/>
		);
	}

	return (
		<>
			<PerspectiveLinkSidebarEntry
				perspectiveDescriptor={perspectiveDescriptor}
				active
				view={defaultView}
				hash={hash}
			/>
			<div id="sub-menu-container" className="ui secondary left pointing vertical menu" style={{ width: '100%' }}>
				{views.map(view => (
					<div key={view.anchor} className="item-wrapper">
						<SidebarSubViewMenuEntry
							perspectiveDescriptor={perspectiveDescriptor}
							view={view}
							active={hash.isView(view)}
							hash={hash}
						/>
					</div>
				))}
			</div>
		</>
	);
}

type ViewLinkContext = {
	perspectiveDescriptor: PerspectiveViewDescriptorBase;
	view: ViewDescriptor;
	hash: NavigationHash;
};

/**
 * Creates the perspective link with a floating menu for switching views within a non-active perspective or from a
 * collapsed sidebar.
 */
export function PerspectiveSubViewPopupMenu({
	views,
	defaultView,
	perspectiveDescriptor
}: PerspectiveSubViewMenuProps): JSX.Element | null {
	const hash = useNavigationHash();
	const [opened, setOpened] = useState(false);
	const activePerspective = hash.getPerspective();
	const isActivePerspective = perspectiveDescriptor.perspective.name === activePerspective.name;
	const context = usePerspectiveContext();
	if (views.length === 0) {
		return (
			<PerspectiveLinkSidebarEntry
				perspectiveDescriptor={perspectiveDescriptor}
				active={isActivePerspective}
				view={defaultView}
				hash={hash}
			/>
		);
	}

	return (
		<Menu
			unstyled
			trigger="hover"
			openDelay={100}
			closeDelay={100}
			position="right-start"
			offset={0}
			opened={opened}
			onChange={setOpened}
		>
			<Menu.Target>
				<PerspectiveLinkSidebarEntry
					perspectiveDescriptor={perspectiveDescriptor}
					active={opened || isActivePerspective}
					hash={hash}
					view={defaultView}
				/>
			</Menu.Target>
			<Menu.Dropdown className="right top ui popup vertical borderless menu navigation-popup">
				<Menu.Item
					component={TeamscaleLink}
					className="item"
					to={perspectiveDescriptor.getLinkToPerspective(context, hash, defaultView)}
					data-testid={`popup-${perspectiveDescriptor.perspective.simpleName}-link`}
				>
					<strong>{perspectiveDescriptor.perspective.displayName}</strong>
				</Menu.Item>
				<Divider className="marginless min-w-[15rem]" />
				{views.map(view => (
					<Menu.Item
						component={SidebarSubViewMenuEntry}
						key={view.anchor}
						view={view}
						active={hash.isView(view)}
						hash={hash}
						className="tooltip-item-list perspective-link"
						perspectiveDescriptor={perspectiveDescriptor}
					/>
				))}
			</Menu.Dropdown>
		</Menu>
	);
}

type PerspectiveLinkSidebarEntryProps = ComponentProps<'a'> &
	ViewLinkContext & {
		active: boolean;
	};

function PerspectiveLinkSidebarEntry({
	ref,
	perspectiveDescriptor,
	active,
	view,
	hash,
	...props
}: PerspectiveLinkSidebarEntryProps) {
	// We don't want onClick to trigger the popup menu
	const { onClick, ...restProps } = props;
	const context = usePerspectiveContext();
	return (
		<TeamscaleLink
			{...restProps}
			ref={ref}
			id={'link-' + perspectiveDescriptor.perspective.simpleName}
			className={clsx('perspective-link item', { active })}
			to={perspectiveDescriptor.getLinkToPerspective(context, hash, view)}
		>
			<div className="item-wrapper">
				{perspectiveDescriptor.perspective.iconClass ? (
					<Icon name={perspectiveDescriptor.perspective.iconClass as SemanticICONS} />
				) : null}
				<span className="item__text">{perspectiveDescriptor.perspective.displayName}</span>
			</div>
		</TeamscaleLink>
	);
}

type SidebarSubViewMenuEntryProps = ComponentProps<'a'> &
	ViewLinkContext & {
		active: boolean;
	};

/** Renders the link for a specific subview either in the sidebar or the sidebar's floating menu. */
function SidebarSubViewMenuEntry({
	view,
	active,
	hash,
	perspectiveDescriptor,
	className,
	...props
}: SidebarSubViewMenuEntryProps) {
	const context = usePerspectiveContext();
	return (
		<TeamscaleLink
			data-name={view.name}
			className={clsx(className, 'item', 'view-switcher-' + view.anchor, { active })}
			to={perspectiveDescriptor.getLinkToPerspective(context, hash, view)}
			{...props}
		>
			{view.name}
			{view.isBeta ? <BetaLabel className="ml-3" /> : null}
		</TeamscaleLink>
	);
}
