import { type ElementType, type RefObject } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { Checkbox, type CheckboxProps } from '../Checkbox';
import { getComponentType, getUnhandledProps } from '../lib';
import { type BasicFormFieldProps, FormField } from './FormField';

/** Props for {@link FormCheckbox}. */
export type FormCheckboxProps = ExtendTypeWith<
	ExtendTypeWith<CheckboxProps, BasicFormFieldProps>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** HTML input type, either checkbox or radio. */
		type?: 'checkbox' | 'radio';

		/** The ref allows retrieving a reference to the underlying DOM node. */
		ref?: RefObject<HTMLInputElement>;
	}
>;

/**
 * Sugar for <Form.Field control={Checkbox} />.
 *
 * @see Checkbox
 * @see Form
 */
export function FormCheckbox(props: FormCheckboxProps) {
	const { control = Checkbox } = props;

	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props, { defaultAs: FormField });

	return <ElementType {...rest} control={control} />;
}
const handledProps = ['as', 'control'];
