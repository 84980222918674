import { Rating } from '@mantine/core';
import { MUTATION, useMutationOperation } from 'api/Mutation';
import { QUERY } from 'api/Query';
import { type Control, FormProvider, useController, useForm, type UseFormReturn } from 'react-hook-form';
import { GlobalFormErrors } from 'ts/commons/forms/GlobalFormErrors';
import { Button } from 'ts/components/Button';
import { Form, FormCheckbox, FormField, FormTextArea } from 'ts/components/Form';
import { Modal, ModalActionButtons } from 'ts/components/Modal';

/** Displays the customer satisfaction dialog. */
export function UserSatisfactionDialog({ onClose }: { onClose: () => void }) {
	const onAskLater = () => {
		void QUERY.requestAskLaterForUserSatisfactionFeedback().fetch();
		onClose();
	};
	const onDismiss = () => {
		void QUERY.dismissUserSatisfactionFeedbackPrompting().fetch();
		onClose();
	};
	return (
		<Modal opened size="md" title="How do you like Teamscale?" onClose={onAskLater}>
			<UserSatisfactionDialogContent onAskLater={onAskLater} onDismiss={onDismiss} onSubmit={onClose} />
		</Modal>
	);
}

function getFullRatingSymbol(value: number) {
	return (
		<div className="font-bold text-center text-white min-w-9 border border-solid border-stone-400 bg-[#2185d0] p-2 ml-2 mr-2 rounded-lg">
			{value - 1}
		</div>
	);
}

function getEmptyRatingSymbol(value: number) {
	return (
		<div className="text-center min-w-9 border border-solid border-stone-400 p-2 ml-2 mr-2 rounded-lg">
			{value - 1}
		</div>
	);
}

type UserSatisfactionDialogValues = {
	feedback: string;
	rating: number;
	mayBeContacted: boolean;
};

function MayBeContactedCheckbox({ formContext }: FormContextProps) {
	const mayBeContactedController = useController({ name: 'mayBeContacted', control: formContext.control });
	return (
		<FormCheckbox
			checked={mayBeContactedController.field.value}
			onChange={(_, data) => mayBeContactedController.field.onChange(data.checked)}
			label="Teamscale Customer Service may contact me regarding my feedback."
		/>
	);
}

type FormContextProps = {
	formContext: UseFormReturn<UserSatisfactionDialogValues>;
};

function FeedbackTextArea({ formContext }: FormContextProps) {
	return (
		<FormTextArea
			{...formContext.register('feedback')}
			label="How could we improve Teamscale to make it more valuable to you?"
			rows={2}
		/>
	);
}

type UserSatisfactionRatingProps = {
	control: Control<UserSatisfactionDialogValues>;
};

function UserSatisfactionRating({ control }: UserSatisfactionRatingProps) {
	const ratingController = useController({
		name: 'rating',
		control,
		rules: {
			validate: rating => {
				return rating !== 0 || 'Please choose a rating from 0 to 10.';
			}
		}
	});
	return (
		<FormField>
			<label htmlFor="customer-satisfaction-rating-input">
				How likely are you to recommend Teamscale within your professional network?
				<Rating
					id="customer-satisfaction-rating-input"
					className="m-4"
					fullSymbol={getFullRatingSymbol}
					emptySymbol={getEmptyRatingSymbol}
					value={ratingController.field.value}
					onChange={ratingController.field.onChange}
					size="lg"
					count={11}
				/>
			</label>
			<span className="pull-left -mt-2 ml-6">Not likely</span>
			<span className="pull-right -mt-2 mr-6">Very likely</span>
		</FormField>
	);
}

type UserSatisfactionFormContentProps = {
	formContext: UseFormReturn<UserSatisfactionDialogValues>;
	onClose: () => void;
};

function UserSatisfactionFormContent({ formContext, onClose }: UserSatisfactionFormContentProps) {
	const { mutate: submitUserSatisfactionFeedback } = useMutationOperation(MUTATION.submitUserSatisfactionFeedback, {
		onSuccess() {
			onClose();
		},
		onError: error => formContext.setError('root.serverError', { message: error.errorSummary })
	});
	const onSubmit = (values: UserSatisfactionDialogValues) => {
		submitUserSatisfactionFeedback({
			body: {
				date: undefined,
				feedback: values.feedback,
				mayBeContacted: values.mayBeContacted,
				rating: values.rating - 1
			}
		});
	};
	return (
		<Form error onSubmit={formContext.handleSubmit(onSubmit)} id="customer-satisfaction-form">
			<GlobalFormErrors />
			<UserSatisfactionRating control={formContext.control} />
			<div className="min-h-10" />
			<FeedbackTextArea formContext={formContext} />
			<div className="min-h-5" />
			<MayBeContactedCheckbox formContext={formContext} />
		</Form>
	);
}

function UserSatisfactionDialogContent({
	onAskLater,
	onDismiss,
	onSubmit
}: {
	onAskLater: () => void;
	onDismiss: () => void;
	onSubmit: () => void;
}) {
	const formContext = useForm<UserSatisfactionDialogValues>({
		defaultValues: {
			feedback: '',
			rating: 0,
			mayBeContacted: false
		}
	});

	return (
		<FormProvider {...formContext}>
			<UserSatisfactionFormContent formContext={formContext} onClose={onSubmit} />
			<ModalActionButtons>
				<Button
					type="submit"
					primary
					content="Submit"
					title="Submit your feedback"
					form="customer-satisfaction-form"
				/>
				<Button
					type="button"
					content="Ask later"
					title="Ask again at a later point in time"
					onClick={onAskLater}
				/>
				<Button
					type="button"
					content="Dismiss permanently"
					title="Don't prompt for feedback again"
					onClick={onDismiss}
				/>
			</ModalActionButtons>
		</FormProvider>
	);
}
