import { useEffect } from 'react';
import { type CUSTOM_LOCAL_STORAGE_EVENT_NAME } from 'ts/commons/hooks/UseLocalStorage';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface WindowEventMap {
		[CUSTOM_LOCAL_STORAGE_EVENT_NAME]: StorageEvent;
	}
}

/** Hook that adds a change event listener to the window or a list of elements, and removes it on unmount. */
export function useEventListener<K extends keyof WindowEventMap>(
	eventType: K,
	eventHandler: (this: Window, ev: WindowEventMap[K]) => unknown
): void;
export function useEventListener(eventType: string, eventHandler: EventListener): void;
export function useEventListener(eventType: string, eventHandler: EventListener, elements?: Element[]): void;
export function useEventListener(
	eventType: string,
	eventHandler: EventListener,
	elements: Array<Element | Window> = [window]
): void {
	useEffect(() => {
		elements.forEach(element => element.addEventListener(eventType, eventHandler));
		return () => elements.forEach(element => element.removeEventListener(eventType, eventHandler));
	}, [elements, eventType, eventHandler]);
}
