// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './../../../commons/LinkTemplate.soy.generated.js';
import * as $soy$ts$commons$UIUtilsTemplate from './../../../commons/UIUtilsTemplate.soy.generated.js';


/**
 * @param {!$coverageSetting.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $coverageSetting = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $coverageSetting$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.partitionsWithoutCoverage, opt_data.lineCoveragePartitions);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} partitionsWithoutCoverage
 * @param {?=} lineCoveragePartitions
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $coverageSetting$ = function($$areYouAnInternalCaller, $ijData, partitionsWithoutCoverage, lineCoveragePartitions) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting']({lineCoveragePartitions: lineCoveragePartitions, partitionsWithoutCoverage: partitionsWithoutCoverage}, $ijData);
  }
  let $output = '<div class="item"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:20"' : '') + '><div class="ui header" id="annotate-test-coverage" style="font-size: 1em; margin-bottom: .5em; margin-top: 1em"><label for="show-testcoverage">Annotate test coverage</label><span id="color-legend" class="ml-1"></span><div class="ui checkbox pull-right show-coverage-checkbox"><input type="checkbox" id="show-testcoverage"/><label></label></div><i class="coverage-loading-spinner blue spinner loading icon pull-right"></i></div></div>' + (lineCoveragePartitions.length > 0 ? '<div id="partition-selector-container"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:32"' : '') + '></div>' : '') + '<table class="ui very basic compact table"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:34"' : '') + '>';
  if (soy.$$equals(lineCoveragePartitions.length, 0)) {
    $output += '<tbody><tr><td style="font-weight: normal;" colspan="2">No coverage uploaded, showing only coverable lines</td></tr></tbody>';
  } else {
    $output += '<thead><thead><tr><th>Coverage source</th><th>Latest Upload</th></tr></thead><tbody>';
    const partition3343List = lineCoveragePartitions;
    const partition3343ListLen = partition3343List.length;
    for (let partition3343Index = 0; partition3343Index < partition3343ListLen; partition3343Index++) {
      const partition3343Data = partition3343List[partition3343Index];
      $output += '<tr><td>' + soy.$$escapeHtml(partition3343Data.name) + '</td><td>' + soy.$$escapeHtml(partition3343Data.formattedTimestamp) + '</td></tr>';
    }
    if (partitionsWithoutCoverage.length > 0) {
      $output += '<tr><td colspan="2"><i>';
      if (partitionsWithoutCoverage.length > 5) {
        $output += 'No coverage in other coverage sources';
      } else {
        $output += 'No coverage in coverage sources:&nbsp;';
        const partitionWithoutCoverage3376List = partitionsWithoutCoverage;
        const partitionWithoutCoverage3376ListLen = partitionWithoutCoverage3376List.length;
        for (let partitionWithoutCoverage3376Index = 0; partitionWithoutCoverage3376Index < partitionWithoutCoverage3376ListLen; partitionWithoutCoverage3376Index++) {
          const partitionWithoutCoverage3376Data = partitionWithoutCoverage3376List[partitionWithoutCoverage3376Index];
          $output += (partitionWithoutCoverage3376Index > 0 ? ', ' : '') + soy.$$escapeHtml(partitionWithoutCoverage3376Data);
        }
      }
      $output += '</i></td></tr>';
    }
    $output += '</tbody>';
  }
  $output += '</table>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $coverageSetting as coverageSetting };
export { $coverageSetting$ as coverageSetting$ };
/**
 * @typedef {{
 *  lineCoveragePartitions?: (?|undefined),
 *  partitionsWithoutCoverage: ?,
 * }}
 */
$coverageSetting.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $coverageSetting.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting';
}


/**
 * @param {!$dependencyDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $dependencyDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dependencyDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.pathDependencies, opt_data.inversePathDependencies, opt_data.maxDialogContentHeight, opt_data.maxDialogContentWidth, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {!Array<?>} pathDependencies
 * @param {!Array<?>} inversePathDependencies
 * @param {?} maxDialogContentHeight
 * @param {?} maxDialogContentWidth
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dependencyDialog$ = function($$areYouAnInternalCaller, $ijData, project, pathDependencies, inversePathDependencies, maxDialogContentHeight, maxDialogContentWidth, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog']({project: project, pathDependencies: pathDependencies, inversePathDependencies: inversePathDependencies, maxDialogContentHeight: maxDialogContentHeight, maxDialogContentWidth: maxDialogContentWidth, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(Array.isArray(pathDependencies), 'pathDependencies', pathDependencies, '@param', '!Array<?>');
  soy.assertParamType(Array.isArray(inversePathDependencies), 'inversePathDependencies', inversePathDependencies, '@param', '!Array<?>');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(soy.$$coerceToBoolean(pathDependencies) || soy.$$coerceToBoolean(inversePathDependencies) ? '<div class="dependency-dialog-content" style="max-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentHeight)) + 'px; max-width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentWidth)) + 'px; overflow: auto;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:94"' : '') + '><table class="ui very basic compact table">' + $dependencyTableHead$(soy.$$internalCallMarkerDoNotUse, $ijData, 'long arrow right icon', 'Outgoing', pathDependencies.length) + $dependencyTableBody$(soy.$$internalCallMarkerDoNotUse, $ijData, project, pathDependencies, true, commit) + '</table><table class="ui very basic compact table">' + $dependencyTableHead$(soy.$$internalCallMarkerDoNotUse, $ijData, 'long arrow left icon', 'Incoming', inversePathDependencies.length) + $dependencyTableBody$(soy.$$internalCallMarkerDoNotUse, $ijData, project, inversePathDependencies, false, commit) + '</table></div>' : '<div class="dependency-dialog-content-missing"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:126"' : '') + '><p>There is no dependency information available for this file.</p><p>Make sure architecture analysis is enabled.</p></div>');
};
export { $dependencyDialog as dependencyDialog };
export { $dependencyDialog$ as dependencyDialog$ };
/**
 * @typedef {{
 *  project: string,
 *  pathDependencies: !Array<?>,
 *  inversePathDependencies: !Array<?>,
 *  maxDialogContentHeight: ?,
 *  maxDialogContentWidth: ?,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$dependencyDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dependencyDialog.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog';
}


/**
 * @param {!$dependencyTableHead.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 */
const $dependencyTableHead = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dependencyTableHead$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.arrowDirection, opt_data.message, opt_data.dependencyCount);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} arrowDirection
 * @param {?} message
 * @param {number} dependencyCount
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $dependencyTableHead$ = function($$areYouAnInternalCaller, $ijData, arrowDirection, message, dependencyCount) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead']({arrowDirection: arrowDirection, message: message, dependencyCount: dependencyCount}, $ijData);
  }
  soy.assertParamType(typeof dependencyCount === 'number', 'dependencyCount', dependencyCount, '@param', 'number');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<thead' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:145"' : '') + '><th><span class="file text icon"></span><span class="' + soy.$$escapeHtmlAttribute(arrowDirection) + '" style="margin-left: 4px; margin-right: 4px"></span><span class="file text outline icon"></span><span style="margin-left: 4px">' + soy.$$escapeHtml(message) + ' (' + soy.$$escapeHtml(dependencyCount) + ')</span></th><th></th><thead>');
};
/**
 * @typedef {{
 *  arrowDirection: ?,
 *  message: ?,
 *  dependencyCount: number,
 * }}
 */
$dependencyTableHead.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dependencyTableHead.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead';
}


/**
 * @param {!$dependencyTableBody.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 */
const $dependencyTableBody = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dependencyTableBody$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.dependencies, opt_data.isOutgoing, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {!Array<?>} dependencies
 * @param {boolean} isOutgoing
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $dependencyTableBody$ = function($$areYouAnInternalCaller, $ijData, project, dependencies, isOutgoing, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody']({project: project, dependencies: dependencies, isOutgoing: isOutgoing, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(Array.isArray(dependencies), 'dependencies', dependencies, '@param', '!Array<?>');
  soy.assertParamType(typeof isOutgoing === 'boolean', 'isOutgoing', isOutgoing, '@param', 'boolean');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  let $output = '<tbody' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:173"' : '') + '>';
  if (soy.$$coerceToBoolean(dependencies) && dependencies.length > 0) {
    const dependency3522List = dependencies;
    const dependency3522ListLen = dependency3522List.length;
    for (let dependency3522Index = 0; dependency3522Index < dependency3522ListLen; dependency3522Index++) {
      const dependency3522Data = dependency3522List[dependency3522Index];
      $output += '<tr><td width="70%"><a class="dependency-link" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.code(/** @type {?} */ ({project: project, uniformPath: dependency3522Data.dependencyUniformPath, commit: commit, selection: isOutgoing ? null : {startLine: dependency3522Data.dependencyLocations[/** @type {?} */ (0)], endLine: dependency3522Data.dependencyLocations[/** @type {?} */ ('0')]}}), $ijData))) + '">' + soy.$$escapeHtml(dependency3522Data.dependencyUniformPath) + '</a></td><td width="30%">';
      const i3558ListLen = Math.max(0, Math.ceil((10 - 0) / 1));
      for (let i3558Index = 0; i3558Index < i3558ListLen; i3558Index++) {
        const i3558Data = 0 + i3558Index * 1;
        $output += dependency3522Data.dependencyLocations[/** @type {?} */ (i3558Data)] ? (i3558Data > 0 ? ', ' : '') + '<a class="dependency-link" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.code(/** @type {?} */ ({project: project, uniformPath: dependency3522Data.dependencyLocationUniformPath, commit: commit, selection: {startLine: dependency3522Data.dependencyLocations[/** @type {?} */ (i3558Data)], endLine: dependency3522Data.dependencyLocations[/** @type {?} */ (i3558Data)]}}), $ijData))) + '">' + soy.$$escapeHtml(dependency3522Data.dependencyLocations[/** @type {?} */ (i3558Data)]) + '</a>' : '';
      }
      $output += (dependency3522Data.dependencyLocations.length > 10 ? ', ...' : '') + '</td></tr>';
    }
  } else {
    $output += '<tr><td>No results found.</td></tr>';
  }
  $output += '</tbody>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  project: string,
 *  dependencies: !Array<?>,
 *  isOutgoing: boolean,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$dependencyTableBody.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dependencyTableBody.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody';
}


/**
 * @param {!$architectureComponentAssignmentsDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $architectureComponentAssignmentsDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $architectureComponentAssignmentsDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.componentAssignments, opt_data.maxDialogContentHeight, opt_data.maxDialogContentWidth, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {?} componentAssignments
 * @param {number} maxDialogContentHeight
 * @param {number} maxDialogContentWidth
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $architectureComponentAssignmentsDialog$ = function($$areYouAnInternalCaller, $ijData, project, componentAssignments, maxDialogContentHeight, maxDialogContentWidth, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog']({project: project, commit: commit, componentAssignments: componentAssignments, maxDialogContentHeight: maxDialogContentHeight, maxDialogContentWidth: maxDialogContentWidth}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(typeof maxDialogContentHeight === 'number', 'maxDialogContentHeight', maxDialogContentHeight, '@param', 'number');
  soy.assertParamType(typeof maxDialogContentWidth === 'number', 'maxDialogContentWidth', maxDialogContentWidth, '@param', 'number');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(soy.$$coerceToBoolean(componentAssignments) && componentAssignments.length > 0 ? '<div class="dependency-dialog-content" style="max-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentHeight)) + 'px; max-width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentWidth)) + 'px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:232"' : '') + '><table class="ui very basic compact table">' + $architectureTableBody(/** @type {?} */ ({project: project, commit: commit, componentAssignments: componentAssignments}), $ijData) + '</table></div>' : '<div class="dependency-dialog-content-missing"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:244"' : '') + '><p>There are no architectures available for this project.</p></div>');
};
export { $architectureComponentAssignmentsDialog as architectureComponentAssignmentsDialog };
export { $architectureComponentAssignmentsDialog$ as architectureComponentAssignmentsDialog$ };
/**
 * @typedef {{
 *  project: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  componentAssignments: ?,
 *  maxDialogContentHeight: number,
 *  maxDialogContentWidth: number,
 * }}
 */
$architectureComponentAssignmentsDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $architectureComponentAssignmentsDialog.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog';
}


/**
 * @param {!$architectureTableBody.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $architectureTableBody = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {?} */
  const commit = opt_data.commit;
  /** @type {?} */
  const componentAssignments = opt_data.componentAssignments;
  let $output = '<thead' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:261"' : '') + '><th><span style="margin-left: 4px">Architecture</span></th><th><span style="margin-left: 4px">Component</span></th><thead><tbody>';
  const assignment3679List = componentAssignments;
  const assignment3679ListLen = assignment3679List.length;
  for (let assignment3679Index = 0; assignment3679Index < assignment3679ListLen; assignment3679Index++) {
    const assignment3679Data = assignment3679List[assignment3679Index];
    $output += '<tr><td width="50%"><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.architectureEditor(soy.$$assignDefaults({assessmentIdentifier: assignment3679Data.architecturePath, project: project, component: assignment3679Data.componentName}, opt_data), $ijData))) + '">' + soy.$$escapeHtml(assignment3679Data.architecturePath) + '</a></td><td width="50%">';
    const $tmp = assignment3679Data.sortKey;
    switch (google.isObject($tmp) ? $tmp.toString() : $tmp) {
      case 0:
        $output += '<div class="ui  blue label"><i class="clone icon"></i>/' + soy.$$escapeHtml(assignment3679Data.componentName) + '</div>';
        break;
      case 1:
        $output += '<div class="ui red label"><i class="exclamation triangle icon"></i>' + soy.$$escapeHtml(assignment3679Data.componentName) + '</div>';
        break;
      case 2:
        $output += '<div class="ui grey label"><i class="minus icon"></i>' + soy.$$escapeHtml(assignment3679Data.componentName) + '</div>';
        break;
    }
    $output += '</td></tr>';
  }
  $output += '</tbody>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  project: string,
 *  commit: ?,
 *  componentAssignments: ?,
 * }}
 */
$architectureTableBody.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $architectureTableBody.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody';
}


/**
 * @param {!$outdatedCoverageInfo.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $outdatedCoverageInfo = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $outdatedCoverageInfo$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.codeDate);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} codeDate
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $outdatedCoverageInfo$ = function($$areYouAnInternalCaller, $ijData, codeDate) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo']({codeDate: codeDate}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="test-coverage-date-warning" class="ui warning message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:310"' : '') + '>Test coverage information in one or more partition (see right sidebar) may be outdated as code was changed on ' + soy.$$escapeHtml(codeDate) + '.</div>');
};
export { $outdatedCoverageInfo as outdatedCoverageInfo };
export { $outdatedCoverageInfo$ as outdatedCoverageInfo$ };
/**
 * @typedef {{
 *  codeDate: ?,
 * }}
 */
$outdatedCoverageInfo.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $outdatedCoverageInfo.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $mcdcCoverageInfo = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="mcdc-coverage-info" class="ui info message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:322"' : '') + '>MC/DC coverage annotation active. Please hover over the numbers to get an explanation of the values.<span class="pull-right"><i id="dismiss-mcdc-info" class="close icon info-message-color"></i></span></div>');
};
export { $mcdcCoverageInfo as mcdcCoverageInfo };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $mcdcCoverageInfo.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo';
}


/**
 * @param {!$simulinkContainer.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $simulinkContainer = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkContainer$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.useContainerStyles);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} useContainerStyles
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkContainer$ = function($$areYouAnInternalCaller, $ijData, useContainerStyles) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer']({useContainerStyles: useContainerStyles}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="simulink-container"' + (useContainerStyles ? ' style="height: 100%; border: 1px solid whiteSmoke; border-radius: 4px; overflow: auto; max-height: 100vh;"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:335"' : '') + '></div>');
};
export { $simulinkContainer as simulinkContainer };
export { $simulinkContainer$ as simulinkContainer$ };
/**
 * @typedef {{
 *  useContainerStyles: ?,
 * }}
 */
$simulinkContainer.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkContainer.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkNoDrawableModel = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:345"' : '') + '>Simulink model cannot be drawn. See the logs for possible reasons</div>');
};
export { $simulinkNoDrawableModel as simulinkNoDrawableModel };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkNoDrawableModel.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel';
}


/**
 * @param {!$simulinkFindingsMarkerTooltip.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $simulinkFindingsMarkerTooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkFindingsMarkerTooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.findings, opt_data.colors, opt_data.qualifiedNames);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} project
 * @param {?} findings
 * @param {?} colors
 * @param {?} qualifiedNames
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkFindingsMarkerTooltip$ = function($$areYouAnInternalCaller, $ijData, project, findings, colors, qualifiedNames) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip']({project: project, findings: findings, colors: colors, qualifiedNames: qualifiedNames}, $ijData);
  }
  let $output = '<div class="tableWithClickableRows"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:361"' : '') + '><row><p>Assessment</p><p>Qualified Name</p><p>Category</p><p>Message</p></row>';
  const finding3873List = findings;
  const finding3873ListLen = finding3873List.length;
  for (let finding3873Index = 0; finding3873Index < finding3873ListLen; finding3873Index++) {
    const finding3873Data = finding3873List[finding3873Index];
    let findingDetailsLink__soy3875 = '' + $soy$ts$commons$LinkTemplate.findingDetails(/** @type {?} */ ({project: project, id: finding3873Data[/** @type {?} */ ('id')]}), $ijData);
    $output += '<row data-href="' + soy.$$escapeHtmlAttribute(findingDetailsLink__soy3875) + '" style="cursor: pointer"><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy3875)) + '"><div style="width: 20px; background-color:' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(colors[/** @type {?} */ (finding3873Data.assessment)].hexValue)) + '">&nbsp;</div></a><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy3875)) + '">' + soy.$$escapeHtml(qualifiedNames[/** @type {?} */ (finding3873Index)]) + '</a><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy3875)) + '">' + soy.$$escapeHtml(finding3873Data.categoryName) + '</a><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy3875)) + '">' + $soy$ts$commons$UIUtilsTemplate.findingMessage$(soy.$$internalCallMarkerDoNotUse, $ijData, finding3873Data) + '</a></row>';
  }
  $output += '</div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $simulinkFindingsMarkerTooltip as simulinkFindingsMarkerTooltip };
export { $simulinkFindingsMarkerTooltip$ as simulinkFindingsMarkerTooltip$ };
/**
 * @typedef {{
 *  project: ?,
 *  findings: ?,
 *  colors: ?,
 *  qualifiedNames: ?,
 * }}
 */
$simulinkFindingsMarkerTooltip.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkFindingsMarkerTooltip.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip';
}
