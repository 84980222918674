import { useDisclosure } from '@mantine/hooks';
import { type JSX, useState } from 'react';
import { useUserPermissionInfo } from 'ts/base/hooks/PermissionInfoHook';
import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { openAboutTeamscaleModal } from 'ts/base/scaffolding/AboutTeamscaleModal';
import { KeyboardShortcutRegistry } from 'ts/base/scaffolding/KeyboardShortcutRegistry';
import { TeamscaleSupportModal } from 'ts/base/scaffolding/TeamscaleSupportModal';
import {
	DECLARATION_OF_ACCESSIBILITY_DISPLAY_NAME,
	DECLARATION_OF_ACCESSIBILITY_LINK,
	useDeclarationOfAccessibilityExistence
} from 'ts/commons/accessibility/DeclarationOfAccessibility';
import { UserSatisfactionDialog } from 'ts/commons/dialog/UserSatisfactionDialog';
import { Links } from 'ts/commons/links/Links';
import { linkTo } from 'ts/commons/links/LinkTo';
import type { DropdownItemProps } from 'ts/components/Dropdown';
import { Dropdown } from 'ts/components/Dropdown';
import { Icon } from 'ts/components/Icon';
import { ESnippetAnalysisPerspectiveView } from 'ts/perspectives/snippet_anaylsis/ESnippetAnalysisPerspectiveView';
import { EFeatureToggle } from 'typedefs/EFeatureToggle';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';
import styles from './PerspectiveSettingsBar.module.less';

/** Shows the question mark dropdown in the top right with the help/support menu popup. */
export function HelpMenu(): JSX.Element {
	const teamscaleInfo = useTeamscaleInfo();
	const [isTeamscaleSupportModalOpen, { open: openTeamscaleSupportModal, close: closeTeamscaleSupportModal }] =
		useDisclosure(false);
	const options: DropdownItemProps[] = [];
	options.push({
		key: 'whats_new',
		icon: <Icon color="grey" name="bullhorn" />,
		text: "What's New in Teamscale",
		className: 'left',
		as: 'a',
		href: 'documentation/changelog',
		target: '_blank'
	});
	options.push({
		key: 'docs',
		icon: <Icon color="grey" name="book" />,
		text: 'Documentation',
		className: 'left',
		as: 'a',
		href: 'documentation/',
		target: '_blank'
	});
	options.push({
		key: 'rules',
		icon: <Icon color="grey" name="world" />,
		text: 'Check Explorer',
		className: 'left',
		as: TeamscaleLink,
		to: Links.showCheckExplorer()
	});

	options.push({
		key: 'snippet_analysis',
		icon: <Icon color="grey" name="window maximize outline" />,
		text: 'Snippet Analysis',
		className: 'left',
		as: TeamscaleLink,
		to: linkTo(ESnippetAnalysisPerspectiveView.SNIPPET_ANALYSIS)
	});

	const permissionInfo = useUserPermissionInfo();
	if (permissionInfo.hasGlobalPermission(EGlobalPermission.CREATE_SUPPORT_REQUEST)) {
		options.push({
			key: 'create-support-request',
			icon: <Icon color="grey" name="file alternate outline" />,
			text: 'Create Support Request',
			className: 'left',
			onClick: () => openTeamscaleSupportModal()
		});
	}
	options.push({
		key: 'shortcuts',
		icon: <Icon color="grey" name="lightbulb outline" />,
		text: 'Keyboard shortcuts',
		className: 'left',
		onClick: () => KeyboardShortcutRegistry.INSTANCE.showHelp()
	});
	options.push({
		key: 'api',
		icon: <Icon color="grey" name="code" />,
		text: 'API Reference',
		className: 'left',
		as: TeamscaleLink,
		to: Links.showPublicApiDocumentation()
	});
	options.push({
		key: 'support',
		icon: <Icon color="grey" name="envelope outline" />,
		text: 'Get Support',
		className: 'left',
		as: 'a',
		href: 'https://teamscale.com/support',
		target: '_blank',
		rel: 'noopener noreferrer'
	});
	const [showUserSatisfactionModal, setShowUserSatisfactionModal] = useState(false);
	if (teamscaleInfo.enabledFeatureToggles.includes(EFeatureToggle.ENABLE_CUSTOMER_SATISFACTION_DIALOG.name)) {
		options.push({
			key: 'feedback',
			icon: <Icon color="grey" name="comment dots" />,
			text: 'Provide Feedback',
			className: 'left',
			onClick: () => setShowUserSatisfactionModal(true)
		});
	}
	options.push({
		key: 'about',
		icon: <Icon color="grey" name="info circle" />,
		text: 'About Teamscale',
		className: 'left',
		onClick: () => openAboutTeamscaleModal(teamscaleInfo.versionDetails)
	});

	const declarationExists = useDeclarationOfAccessibilityExistence();
	if (declarationExists) {
		options.push({
			key: 'accessibility',
			icon: <Icon color="grey" name="universal access" />,
			text: DECLARATION_OF_ACCESSIBILITY_DISPLAY_NAME,
			className: 'left',
			as: 'a',
			href: DECLARATION_OF_ACCESSIBILITY_LINK,
			target: '_blank',
			rel: 'noopener noreferrer'
		});
	}
	return (
		<>
			<Dropdown
				simple
				closeOnChange
				direction="left"
				openOnFocus={false}
				trigger={<Icon size="large" name="question" />}
				icon={false}
				id="help-button"
				className={styles.helpDropdown}
				options={options}
				value={false}
			/>
			{isTeamscaleSupportModalOpen ? <TeamscaleSupportModal onClose={closeTeamscaleSupportModal} /> : null}
			{showUserSatisfactionModal ? (
				<UserSatisfactionDialog onClose={() => setShowUserSatisfactionModal(false)} />
			) : null}
		</>
	);
}
